import { MouseEvent } from 'react';

import CN from 'clsx';
import { observer } from 'mobx-react-lite';

import { useAnalytics } from '@r-client/shared/data/analytics';
import { useAuth } from '@r-client/shared/feature/auth';
import { Tooltip } from '@r-client/shared/ui/core';
import { getCN } from '@r-client/shared/util/core';

import { useFollow } from './hooks/use-follow';
import { ReactComponent as FollowIcon } from './icons/follow.svg';

import styles from './follow.module.scss';

export interface IFollowProps {
  className?:
    | string
    | {
        main?: string;
        button?: string;
        icon?: string;
      };
  following?: boolean;
  offeringSlug: string;
  companyName?: string;
  isSmallCard?: boolean;
}

export const Follow = observer<IFollowProps>(function Follow({
  className,
  companyName,
  isSmallCard,
  following,
  offeringSlug,
}) {
  const analytics = useAnalytics();
  const { viewer } = useAuth();

  const { justFollowed, isFollowing, onFollowClick, onTooltipMouseLeave } =
    useFollow({ following, offeringSlug });
  const regularTooltip = !isFollowing
    ? `Follow ${isSmallCard ? '' : companyName}`
    : justFollowed
    ? `You are following ${companyName}`
    : `Unfollow ${isSmallCard ? '' : companyName}`;
  const smallTooltip = !isFollowing
    ? `Follow`
    : justFollowed
    ? `You are following ${companyName}`
    : `Unfollow`;
  const tooltip = isSmallCard ? smallTooltip : regularTooltip;

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    analytics.track({
      name: isFollowing ? 'deal_followed' : 'deal_unfollowed',
      params: {
        logged_in: !!viewer?.isAuthenticated,
        campaign_id: offeringSlug,
      },
    });
    onFollowClick();
  }

  return (
    <Tooltip
      className={{
        wrap: getCN(className),
      }}
      content={tooltip}
      delayHide={50}
    >
      <button
        className={CN(styles.follow, getCN(className, 'button'), {
          [styles.isSmallCardFollow]: isSmallCard,
        })}
        onMouseLeave={onTooltipMouseLeave}
        onClick={handleClick}
      >
        <FollowIcon
          {...(isSmallCard ? { viewBox: '-5 -5 36 36' } : {})}
          className={CN(styles.icon, getCN(className, 'icon'), {
            [styles.following]: isFollowing,
            [styles.justFollowed]: justFollowed,
          })}
        />
      </button>
    </Tooltip>
  );
});
