import { forwardRef } from 'react';

import CL from 'clsx';

import { InfoGray } from '../icons';
import { SvgIconLight } from '../svg-icon-light';

import styles from './info-icon.module.scss';

export interface IInfoIconProps {
  className?: string;
  pointer?: boolean;
  leftMargin?: 'default';
}

export const InfoIcon = forwardRef<HTMLElement, IInfoIconProps>(
  function InfoIcon({ className, pointer, leftMargin }, ref) {
    return (
      <SvgIconLight
        className={CL(styles.main, className, {
          [styles.pointer]: pointer,
          [styles.leftMarginDefault]: leftMargin === 'default',
        })}
        icon={InfoGray}
        ref={ref}
      />
    );
  }
);
