import { FunctionComponent, ReactNode } from 'react';

import CN from 'clsx';

import { List, Text, Tooltip } from '@r-client/shared/ui/core';
import { Flash, InfoIcon, SvgIconLight } from '@r-client/shared/ui/icons';
import { formatAmount, formatDollars } from '@r-client/shared/util/core';

import styles from './offering-card-terms.module.scss';

export interface IOfferingTerms {
  value?: number | string | null;
  nextTierValue?: string | null;
  title: string;
  valuePreNode?: ReactNode;
  isDollar?: boolean;
  color?: string;
  tooltip?: string | null;
}

export interface IOfferingCardTermsProps {
  className?: string;
  terms?: IOfferingTerms[];
  isSmallCard?: boolean;
}

const ValueBox = ({ item }: { item: IOfferingTerms }) => {
  return (
    <div
      className={CN(styles.leftPart, {
        [styles.specialTerm]: item.nextTierValue,
      })}
    >
      {item.valuePreNode ? <>{item.valuePreNode}&nbsp;</> : null}
      {item.nextTierValue ? <SvgIconLight icon={Flash} /> : null}
      {typeof item.value === 'number' ? (
        item.isDollar ? (
          formatDollars(item.value)
        ) : (
          formatAmount(item.value)
        )
      ) : typeof item.value === 'string' ? (
        <Text style={{ textTransform: 'uppercase' }}>
          {!+item.value ? item.value : formatDollars(+item.value, 'auto', true)}
          {item.nextTierValue ? (
            <>
              &nbsp;
              <span className={styles.strikeThrough}>{item.nextTierValue}</span>
            </>
          ) : null}
        </Text>
      ) : null}
    </div>
  );
};

export const OfferingCardTerms: FunctionComponent<
  React.PropsWithChildren<IOfferingCardTermsProps>
> =
  // TODO: Terms update - https://linear.app/republic/issue/RCE-1289/[pr-task-offeringcardterms]-adjust-to-reflect-schema-update
  function Terms({ className, terms, isSmallCard }) {
    return (
      <List
        className={CN(styles.terms, className, {
          [styles.smallCardTerms]: isSmallCard,
        })}
      >
        {(!isSmallCard ? terms : terms?.slice(0, 2))?.map((item, i) => (
          <List.Item
            className={CN(styles.term, { [styles.smallCardTerm]: isSmallCard })}
            key={i}
            {...(item.color ? { style: { color: item.color } } : {})}
          >
            {isSmallCard ? (
              <>
                <span title={item.title} className={styles.ellipsisText}>
                  {item.title}
                </span>
                &nbsp;
                <ValueBox item={item} />
              </>
            ) : (
              <>
                <ValueBox item={item} />
                &nbsp;
                <Text title={item.title} className={styles.ellipsisText}>
                  {item.title}
                </Text>
                {item.tooltip && (
                  <span className={styles.tooltipWrapper}>
                    <Tooltip content={item.tooltip} interactive>
                      <InfoIcon />
                    </Tooltip>
                  </span>
                )}
              </>
            )}
          </List.Item>
        ))}
      </List>
    );
  };
