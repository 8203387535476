import { FunctionComponent } from 'react';

import { Tag } from '../tag';

import styles from './campaign-tag.module.scss';

export interface ICampaignTagProps {
  className?: string;
  size?: 'small';
  campaignIndex: number;
}

const getOrdinal = (num: number) => {
  switch (num) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const CampaignTag: FunctionComponent<
  React.PropsWithChildren<ICampaignTagProps>
> = function CampaignTag({ className, campaignIndex, ...props }) {
  return (
    <Tag className={className} theme="campaign" {...props}>
      {campaignIndex}
      <sup className={styles.sup}>{getOrdinal(campaignIndex)}</sup>
    </Tag>
  );
};
