/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseMutationModel,
  IMutationModelOpts,
  useGqlClient,
} from '@r-client/shared/data/client';
import { useLocalObservable } from 'mobx-react-lite';
import {
  IFollowOfferingMutation,
  FollowOfferingDocument,
  IFollowOfferingMutationVariables,
} from './types';
import { IFollowCreatePayload } from '@r-client/data/graphql-types';
export type { IFollowCreatePayload };

export class FollowOfferingMutation extends BaseMutationModel<
  IFollowOfferingMutation,
  IFollowOfferingMutationVariables
> {
  get mutation() {
    return FollowOfferingDocument;
  }
}
export function useFollowOfferingMutation(
  opts?: Omit<IMutationModelOpts<IFollowOfferingMutationVariables>, 'client'>
) {
  const client = useGqlClient();
  return useLocalObservable(
    () => new FollowOfferingMutation({ ...opts, client })
  );
}
