import { FunctionComponent } from 'react';

import CN from 'clsx';

import { ITagsTag } from '@r-client/data/graphql-types';
import { Tag } from '@r-client/shared/ui/core';
import { Text } from '@r-client/shared/ui/core';

import styles from './offering-card-footer.module.scss';

export interface IOfferingCardFooterProps {
  className?: string;
  tags?: Pick<ITagsTag, 'colorHex' | 'displayName'>[] | null;
  location?: string;
  isSmallCard?: boolean;
  regulationEntityText?: string;
  regulationClassName?: string;
}
export const OfferingCardFooter: FunctionComponent<
  React.PropsWithChildren<IOfferingCardFooterProps>
> = function Footer({
  tags,
  location,
  className,
  isSmallCard,
  regulationEntityText,
  regulationClassName,
}) {
  return (
    <footer
      className={CN(styles.footer, className, {
        [styles.smallCardFooter]: isSmallCard,
      })}
    >
      {location ? <div className={styles.location}>{location}</div> : null}
      <div className={styles.cards}>
        {tags &&
          [...tags]
            .sort(
              ({ colorHex: colorHexA }, { colorHex: colorHexB }) =>
                +!!colorHexB - +!!colorHexA
            )
            .map((tag, i) => (
              <Tag
                key={i}
                className={styles.tag}
                bgColor={tag?.colorHex || ''}
                size="small"
                noBorder={!!tag?.colorHex}
                whiteText={tag.displayName === 'crypto'}
              >
                {tag.displayName}
              </Tag>
            ))}
      </div>
      {!isSmallCard && regulationEntityText && (
        <Text className={CN(styles.regulationEntityText, regulationClassName)}>
          {regulationEntityText}
        </Text>
      )}
    </footer>
  );
};
