// TODO: https://linear.app/republic/issue/ATOM-1294/[pr-tsk-tags]-[fe]-remove-deprecated-legacy-tags-and-switch-to-new
import React, { forwardRef } from 'react';

import CN from 'clsx';

import { getBackgroundColor } from './helper/bg-color';

import styles from './tag.module.scss';

export interface ITagProps extends React.HTMLAttributes<HTMLLabelElement> {
  as?: 'label' | 'span';
  className?: string;
  size?: 'small';
  theme?: 'message' | 'campaign' | 'success' | 'warning' | 'danger';
  whiteText?: boolean;
  bgColor?: string;
  noBorder?: boolean;
}

export const Tag = forwardRef<HTMLLabelElement, ITagProps>(function Tag(
  {
    as = 'label',
    className,
    children,
    bgColor,
    size,
    theme,
    whiteText,
    noBorder,
    ...props
  },
  ref
) {
  const classNames = CN(styles.main, className, {
    [styles.small]: size === 'small',
    [styles.campaign]: theme === 'campaign',
    [styles.whiteText]: !!whiteText,
    [styles.success]: theme === 'success',
    [styles.warning]: theme === 'warning',
    [styles.danger]: theme === 'danger',
    [styles.message]: theme === 'message',
    [styles.noBorder]: noBorder,
  });

  const style = {
    backgroundColor: bgColor ? getBackgroundColor(bgColor) : undefined,
  };

  return React.createElement(
    as,
    { ...props, style, className: classNames, ref },
    children
  );
});
