import cn from 'clsx';

import { IOfferingBadgeNameEnum } from '@r-client/data/graphql-types';
import { Divider, Link } from '@r-client/shared/ui/core';

import { E_OFFERING_INVESTMENT_BADGE_NAME } from '../../../types';
import { IOfferingBadgeProps, OfferingBadge } from './offering-badge';

import styles from './offering-card-badge-wrapper.module.scss';

export interface IOfferingCardBadgeWrapperProps {
  badges?: IOfferingBadgeProps['badge'][];
  learnMore?: string;
  isSmallCard?: boolean;
  leftToInvestValue?: number;
  leftToInvestUnit?: string;
  investmentBadge?: {
    description: E_OFFERING_INVESTMENT_BADGE_NAME;
    amount: number;
  };
}

export const OfferingCardBadgeWrapper = ({
  badges,
  isSmallCard,
  learnMore = '/help/what-does-it-mean-for-a-startup-to-be-marked-as-trending',
  leftToInvestUnit,
  leftToInvestValue,
  investmentBadge,
}: IOfferingCardBadgeWrapperProps) => {
  return (
    <div
      className={cn(
        styles.badgeWrapper,
        'badgeWrapper', // used for specificity in offering-badge.module.scss
        {
          [styles.isSmallCard]: isSmallCard,
        }
      )}
    >
      <div className={styles.badgeWrapperInner}>
        {badges?.map((badge, i) => {
          const content =
            badge === IOfferingBadgeNameEnum.Trending ? (
              <div className={styles.tooltip}>
                <p>
                  The company has seen high investor demand over the past 3
                  days.
                </p>
                <Divider />
                <Link className={styles.badgeLink} href={learnMore}>
                  Learn more
                </Link>
              </div>
            ) : undefined;
          return (
            <OfferingBadge
              isSmallCard={isSmallCard}
              key={i}
              badge={badge}
              content={content}
              leftToInvestUnit={leftToInvestUnit}
              leftToInvestValue={leftToInvestValue}
            />
          );
        })}
        {investmentBadge && (
          <OfferingBadge
            isSmallCard={isSmallCard}
            badge={investmentBadge.description}
            amount={investmentBadge.amount}
            leftToInvestUnit={leftToInvestUnit}
            leftToInvestValue={leftToInvestValue}
          />
        )}
      </div>
    </div>
  );
};
