/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import {
  BaseMutationModel,
  IMutationModelOpts,
  useGqlClient,
} from '@r-client/shared/data/client';
import { useLocalObservable } from 'mobx-react-lite';
import {
  IUnfollowOfferingMutation,
  UnfollowOfferingDocument,
  IUnfollowOfferingMutationVariables,
} from './types';
import { IFollowDeletePayload } from '@r-client/data/graphql-types';
export type { IFollowDeletePayload };

export class UnfollowOfferingMutation extends BaseMutationModel<
  IUnfollowOfferingMutation,
  IUnfollowOfferingMutationVariables
> {
  get mutation() {
    return UnfollowOfferingDocument;
  }
}
export function useUnfollowOfferingMutation(
  opts?: Omit<IMutationModelOpts<IUnfollowOfferingMutationVariables>, 'client'>
) {
  const client = useGqlClient();
  return useLocalObservable(
    () => new UnfollowOfferingMutation({ ...opts, client })
  );
}
