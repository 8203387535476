import { useState } from 'react';

import { useReporting } from '@r-client/shared/data/error-reporting';
import { useAuth } from '@r-client/shared/feature/auth';

import { useFollowOfferingMutation } from '../../../graphql/follow-offering-mutation';
import { useUnfollowOfferingMutation } from '../../../graphql/unfollow-offering-mutation';

export interface IUseFollowOpts {
  offeringSlug: string;
  following?: boolean;
}

export function useFollow({ offeringSlug, following }: IUseFollowOpts) {
  const { viewer } = useAuth();
  const rollbar = useReporting();
  const followOffering = useFollowOfferingMutation({
    variables: {
      input: {
        slug: offeringSlug,
      },
    },
  });
  const unfollowOffering = useUnfollowOfferingMutation({
    variables: {
      input: {
        slug: offeringSlug,
      },
    },
  });
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isFollowing, setIsFollowing] = useState<boolean>(!!following);
  const [justFollowed, setJustFollowed] = useState<boolean>(false);

  function onFollowClick() {
    if (!viewer?.info?.id) {
      window.location.href = '/register';
    }

    if (isFollowing) {
      unfollow();
    } else {
      follow();
    }
  }

  function onTooltipMouseLeave() {
    setJustFollowed(false);
  }

  async function follow() {
    if (isFetching) {
      return;
    }

    try {
      setIsFetching(true);
      setIsFollowing(true);
      const response = await followOffering.submit();

      if (response && response.data?.follow?.errors?.length) {
        rollbar.error('Follow error', response.data.follow.errors, {
          offeringSlug,
        });
      }

      if (
        response &&
        response.data?.follow?.target?.__typename === 'Offering' &&
        response.data?.follow?.target?.following
      ) {
        setJustFollowed(true);
      } else if (response && response?.data?.follow?.errors) {
        setIsFollowing(false);
      }
    } catch (error) {
      rollbar.error('Follow error', `${error}`, { offeringSlug });
      setIsFollowing(false);
    } finally {
      setIsFetching(false);
    }
  }

  async function unfollow() {
    if (isFetching) {
      return;
    }

    try {
      setIsFetching(true);
      setIsFollowing(false);
      const response = await unfollowOffering.submit();

      if (response && response.data?.unfollow?.errors?.length) {
        rollbar.error('Follow error', response.data.unfollow.errors, {
          offeringSlug,
        });
      }

      if (
        response &&
        response.data?.unfollow?.target?.__typename === 'Offering' &&
        !response.data?.unfollow?.target?.following
      ) {
        setJustFollowed(false);
      } else if (response && response.data?.unfollow?.errors) {
        setIsFollowing(true);
      }
    } catch (error) {
      rollbar.error('Follow error', `${error}`, { offeringSlug });
      setIsFollowing(true);
    } finally {
      setIsFetching(false);
    }
  }

  return { justFollowed, isFollowing, onFollowClick, onTooltipMouseLeave };
}
