import React from 'react';

import CN from 'clsx';

import { IOfferingBadgeNameEnum } from '@r-client/data/graphql-types';
import {
  ITooltipProps,
  Tag,
  Text,
  Tooltip,
  TTag,
} from '@r-client/shared/ui/core';
import {
  Flame,
  Lightning,
  OK,
  Pin,
  SvgIconLight,
} from '@r-client/shared/ui/icons';
import { formatDollarsFromCents, PickProps } from '@r-client/shared/util/core';

import {
  E_MARKETING_BADGES,
  E_OFFERING_INVESTMENT_BADGE_NAME,
} from '../../../types';

import styles from './offering-badge.module.scss';

export interface IOfferingBadgeProps {
  badge?:
    | IOfferingBadgeNameEnum
    | TTag
    | E_OFFERING_INVESTMENT_BADGE_NAME
    | E_MARKETING_BADGES;
  amount?: number;
  content?: PickProps<ITooltipProps, 'content'>;
  isSmallCard?: boolean;
  leftToInvestUnit?: string;
  leftToInvestValue?: number;
}

export const OfferingBadge = ({
  badge,
  amount,
  content,
  isSmallCard,
  leftToInvestUnit,
  leftToInvestValue,
}: IOfferingBadgeProps) => {
  const Body = (
    <Tag
      as="span"
      className={CN(styles.badge, {
        [styles.trend]: badge === IOfferingBadgeNameEnum.Trending,
        [styles.fullyFunded]:
          badge === IOfferingBadgeNameEnum.FullyFunded ||
          badge === E_OFFERING_INVESTMENT_BADGE_NAME.COMMITTED ||
          badge === E_OFFERING_INVESTMENT_BADGE_NAME.WAITLISTED,
        [styles.danger]: badge === E_OFFERING_INVESTMENT_BADGE_NAME.CANCELLED,
        [styles.fullyReserved]: badge === IOfferingBadgeNameEnum.FullyReserved,
        [styles.new]: badge === IOfferingBadgeNameEnum.New,
        [styles.special]: badge === IOfferingBadgeNameEnum.Special,
        // TODO: https://linear.app/republic/issue/RCE-1264/[pr-task-offeringcards]-show-time-to-invest-logic
        [styles.leftToInvest]: badge === IOfferingBadgeNameEnum.TimeToInvest,
        [styles.funded]: badge === IOfferingBadgeNameEnum.Funded,
        [styles.simple]:
          badge === IOfferingBadgeNameEnum.AcceptingReservations ||
          badge === IOfferingBadgeNameEnum.AccreditedOnly ||
          badge === IOfferingBadgeNameEnum.Waitlist ||
          badge === IOfferingBadgeNameEnum.Live ||
          badge === IOfferingBadgeNameEnum.PlatinumMembershipEligible,
        [styles.spotlight]: badge === E_MARKETING_BADGES.SPOTLIGHT,
        [styles.isSmallCardBadge]: isSmallCard,
      })}
      theme={TTag.is(badge) ? badge : undefined}
    >
      {renderBody({
        badge,
        amount,
        content,
        leftToInvestUnit,
        leftToInvestValue,
      })}
    </Tag>
  );

  return content ? (
    <Tooltip
      placement="top"
      delayHide={2000}
      content={content}
      interactive
      className={{ main: styles.tooltipMain }}
    >
      {Body}
    </Tooltip>
  ) : (
    Body
  );
};

function renderBody({
  badge,
  amount,
  leftToInvestUnit,
  leftToInvestValue,
}: IOfferingBadgeProps): React.ReactNode {
  switch (badge) {
    case IOfferingBadgeNameEnum.Special:
      return (
        <>
          <SvgIconLight className={styles.icon} icon={Lightning} height={15} />{' '}
          <Text className={styles.tagText}>Special</Text>
        </>
      );
    case IOfferingBadgeNameEnum.Trending:
      return (
        <>
          <FlameIcon />
          <Text className={styles.tagText}>Trending</Text>
        </>
      );
    // TODO: https://linear.app/republic/issue/RCE-1264/[pr-task-offeringcards]-show-time-to-invest-logic
    case IOfferingBadgeNameEnum.TimeToInvest:
      return (
        <>
          <FlameIcon />
          {leftToInvestValue} {leftToInvestUnit} left to invest
        </>
      );
    case IOfferingBadgeNameEnum.FullyFunded:
      return (
        <>
          <SvgIconLight
            className={CN(styles.icon)}
            icon={OK}
            height={15}
            width={15}
          />
          Sold out
        </>
      );
    case TTag.enum.success:
    case TTag.enum.warning:
    case TTag.enum.danger:
    case E_OFFERING_INVESTMENT_BADGE_NAME.WAITLISTED:
    case E_OFFERING_INVESTMENT_BADGE_NAME.COMMITTED:
    case E_OFFERING_INVESTMENT_BADGE_NAME.CANCELLED:
      return (
        <>
          {badge} {formatDollarsFromCents(amount)}
        </>
      );
    case E_MARKETING_BADGES.SPOTLIGHT:
      return (
        <>
          <SvgIconLight
            className={CN(styles.icon)}
            icon={Pin}
            height={13}
            width={11}
          />
          Spotlight
        </>
      );
    default:
      return badge?.split('_').join(' ');
  }
}

function FlameIcon() {
  return (
    <SvgIconLight
      className={CN(styles.icon, styles.flame)}
      icon={Flame}
      height={15}
    />
  );
}
